<template>
  <main-layout>
    <main class="section">
      <div class="container">
        <div>
          <p
            class="title is-6 has-text-grey has-text-right is-hidden-tablet"
            @click="alphabetShown = !alphabetShown"
          >
            <span v-t="`merchants.a-to-z`"></span>&nbsp;
            <i class="fas fa-angle-down has-text-grey-light" v-if="alphabetShown"></i>
            <i class="fas fa-angle-up has-text-grey-light" v-if="!alphabetShown"></i>
          </p>
          <alphabet v-show="alphabetShown" v-model="selectedLetter"></alphabet>
        </div>

        <div class="spacer is-large"></div>

        <div
          class="box has-padding-4"
          v-for="(letter, key) in filteredLetters"
          :key="key"
        >
          <h3 class="title is-3 has-text-grey">{{ letter.toUpperCase() }}</h3>
          <ul class="flex-list">
            <li v-for="(retailer, key) in sortedRetailers[letter]" :key="key">
              <router-link :to="{ name: 'merchant', params: { merchant: retailer.url } }">
                {{ retailer.merchant ? retailer.merchant.name : retailer.url }}
              </router-link>
            </li>
          </ul>
        </div>
      </div>
    </main>
  </main-layout>
</template>

<script>
import { mapState } from 'vuex'
import Alphabet from './components/Alphabet'

export default {
  components: { Alphabet },

  data () {
    return {
      selectedLetter: undefined,
      alphabetShown: !/(android|ios)/i.test(this.$browser.os)
    }
  },

  computed: {
    ...mapState({
      domains: state => state.activeDomains
    }),

    sortedRetailers () {
      return this.domains.reduce((carry, item) => {
        const name = item.merchant ? item.merchant.name : item.url
        const letter = name.charAt().toLowerCase()
        const symbol = /^[a-z]/.test(letter) ? letter : '#'

        carry[symbol] = (carry[symbol] || []).concat([item])

        return carry
      }, {})
    },

    filteredLetters () {
      return Object.keys(this.sortedRetailers).filter(l => !this.selectedLetter || this.selectedLetter === l).sort()
    }
  }
}
</script>
